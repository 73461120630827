// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

import Container from "../../layout/container"
import CharlotteCard from "./CharlotteCard"

const TestimonySection = ({ langKey }) => {
  return (
    <Container>
      <div
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingTop: `10rem`,
        }}
      >
        <CharlotteCard langKey={langKey} />
      </div>
    </Container>
  )
}

export default TestimonySection
