// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { colors } from "../../../utils/theme/colors"
import _content from "../../../content/homepage/logo-section.json"
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa"

const CharlotteCardContent = ({ langKey }) => {
  const { name, position, paragraph } = _content[langKey]
  return (
    <div>
      <h2
        sx={{
          fontSize: "0.938rem",
          fontWeight: 600,
          textAlign: "center",
          textTransform: "uppercase",
          my: "0.5rem",
        }}
      >
        {name}
      </h2>
      <h3
        sx={{
          fontSize: "0.938rem",
          textAlign: "center",
          color: "#818082",
          my: 0,
        }}
      >
        {position}
      </h3>
      <div
        sx={{
          width: "1.938rem",
          height: "0.188rem",
          backgroundColor: colors.code.primary,
          mx: "auto",
          my: "25px",
        }}
      />
      <FaQuoteLeft
        sx={{
          color: colors.code.secondary,
          marginLeft: `5%`,
        }}
      />
      <p sx={{ px: "2.4rem", my: 0, textAlign: "center" }}>{paragraph}</p>
      <FaQuoteRight sx={{ float: `right`, marginRight: `5%` }} />
    </div>
  )
}

CharlotteCardContent.propTypes = {}

export default CharlotteCardContent
