// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const PublicationsSection = () => {
  const data = useStaticQuery(graphql`
    query NetworkImgQuery {
      networkIcon: file(relativePath: { eq: "network-icon.png" }) {
        childImageSharp {
          fixed(width: 120, height: 97) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <div sx={{ height: "37.25rem", position: "relative" }}>
      <Img
        alt=""
        fixed={data.networkIcon.childImageSharp.fixed}
        sx={{
          position: "absolute",
          top: "30%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  )
}

export default PublicationsSection
