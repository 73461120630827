// eslint-disable-next-line no-unused-vars
/** @jsx jsx */
import { jsx } from "theme-ui"

import { colors } from "../../utils/theme/colors"
import { fontWeights } from "../../utils/theme/font-weights"
import { mediaQueries } from "../../utils/theme/media-queries"
import Container from "../layout/container"
import MapDesktop from "./mapDesktop"
import MapMobile from "./mapMobile"
import ContentHomepage from "../../content/homepage/mapSection.json"
import Span from "../common/span"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const MapSection = ({ langKey, page }) => {
  const {
    title1,
    title2,
    title3,
    title4,
    subtitle,
    pin1Title,
    pin1SubTitle,
    pin2Title,
    pin2SubTitle,
    pin3Title,
    pin3SubTitle,
    pin4Title,
    pin4SubTitle,
    pin5Title,
    pin5SubTitle,
  } = ContentHomepage[langKey]

  const data = useStaticQuery(graphql`
    query MapSectionQuery {
      LogoSeparator: file(relativePath: { eq: "logo-separator.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fixed(width: 39, height: 59) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      desktop: file(relativePath: { eq: "HomepageMapBackground.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile: file(relativePath: { eq: "MapBackgroundMobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 376) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const sources = [
    data.mobile.childImageSharp.fluid,
    {
      ...data.desktop.childImageSharp.fluid,
      media: `(min-width: 750px)`,
    },
  ]

  return (
    <BackgroundImage fluid={sources}>
      <div
        sx={{
          marginTop: "-2px",
          display: `flex`,
        }}
      >
        <Container>
          <div sx={{ marginTop: "-3.69rem", position: "relative" }}>
            <div
              sx={{
                width: `0`,
                height: [`5rem`, `5rem`, `7.38rem`, `7.38rem`],
                objectFit: `contain`,
                border: `solid 1px`,
                margin: `auto`,
                marginTop: [`5rem`, `5rem`, `0px`, `0px`],
                color: colors.code.primary,
              }}
            />

            <div
              sx={{
                display: `flex`,
                paddingTop: `4rem`,
                flexDirection: `column`,
              }}
            >
              <div
                sx={{
                  display: `flex`,
                  flexDirection: `column`,
                  width: [`100%`, `100%`, `100%`, `80%`],
                  alignItems: `center`,
                  margin: `auto`,
                }}
              >
                <div sx={{ display: `flex` }}>
                  <h2
                    sx={{
                      textAlign: `center`,
                      fontWeight: fontWeights.black,
                      color: colors.white,
                      fontSize: [`1.375rem`, `1.375rem`, `2.25rem`, `2.25rem`],
                      alignSelf: `center`,
                    }}
                  >
                    <Span text={title1} />
                    &nbsp;
                    {title2}
                    {title3}
                    {title4}
                  </h2>
                </div>
              </div>
              <p
                sx={{
                  textAlign: `center`,
                  fontSize: `0.94rem`,
                  fontWeight: fontWeights.body,
                  lineHeight: 1.47,
                  color: colors.white,
                  width: `80%`,
                  alignSelf: `center`,
                  [mediaQueries.lg]: {
                    lineHeight: `1.2`,
                    fontSize: `1.375rem`,
                  },
                }}
              >
                {subtitle}
              </p>

              <div
                sx={{
                  display: ["none", "none", "flex", "flex"],
                  marginBottom: "18rem",
                  flexDirection: `column`,
                  alignItems: `center`,
                }}
              >
                <Img
                  alt=""
                  fixed={data.LogoSeparator.childImageSharp.fixed}
                  sx={{ marginBotton: `2rem` }}
                />
                <MapDesktop
                  pin1Title={pin1Title}
                  pin1Subtitle={pin1SubTitle}
                  pin2Title={pin2Title}
                  pin2Subtitle={pin2SubTitle}
                  pin3Title={pin3Title}
                  pin3Subtitle={pin3SubTitle}
                  pin4Title={pin4Title}
                  pin4Subtitle={pin4SubTitle}
                  pin5Title={pin5Title}
                  pin5Subtitle={pin5SubTitle}
                />
              </div>

              <div
                sx={{
                  display: ["flex", "flex", "none", "none"],
                  justifyContent: `center`,
                }}
              >
                <MapMobile
                  pin1Title={pin1Title}
                  pin1Subtitle={pin1SubTitle}
                  pin2Title={pin2Title}
                  pin2Subtitle={pin2SubTitle}
                  pin3Title={pin3Title}
                  pin3Subtitle={pin3SubTitle}
                  pin4Title={pin4Title}
                  pin4Subtitle={pin4SubTitle}
                  pin5Title={pin5Title}
                  pin5Subtitle={pin5SubTitle}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </BackgroundImage>
  )
}

export default MapSection
