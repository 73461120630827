// eslint-disable-next-line no-unused-vars
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
/** @jsx jsx */
import { jsx } from "theme-ui"
import CharlotteCardContent from "./CharlotteCardContent"
import RightLogos from "./RightLogos"
import LeftLogos from "./LeftLogos"

const CharlotteCard = ({ langKey }) => {
  const data = useStaticQuery(graphql`
    query CharlottePageQuery {
      Charlotte: file(relativePath: { eq: "Charlotte.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fixed(width: 125, height: 125, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <>
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          margin: `auto`,
          marginTop: "4rem",
        }}
      >
        <RightLogos />

        <div
          sx={{
            minHeight: "19rem",
            maxWidth: `35rem`,
            py: "3.9065rem",
            borderRadius: "20px",
            border: "solid 1px #e3e3e3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            sx={{
              position: "relative",
              top: "-7.813rem",
              height: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Img alt="" fixed={data.Charlotte.childImageSharp.fixed} />
          </div>
          <CharlotteCardContent langKey={langKey} />
        </div>

        <LeftLogos />
      </div>
    </>
  )
}

CharlotteCard.propTypes = {}

export default CharlotteCard
