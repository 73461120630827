// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"

import { colors } from "../../utils/theme/colors"
import { fontWeights } from "../../utils/theme/font-weights"

const ServiceCard = ({ fixed, title }) => (
  <div
    sx={{
      display: `flex`,
      flexDirection: `column`,
      alignItems: `center`,
      border: `1px solid`,
      borderColor: colors.white,
      borderRadius: `12px`,
      boxShadow: `0 0 35px 0 rgba(31, 31, 31, 0.07)`,
      width: [`18rem`, `18rem`, `220px`, `230px`],
      minHeight: `12rem`,
      backgroundColor: colors.white,
      marginTop: `6rem`,
    }}
  >
    <Img
      alt=""
      sx={{
        marginTop: `-5.8rem`,
      }}
      fixed={fixed}
    />
    <h3
      sx={{
        color: colors.code.secondary,
        marginTop: `3.2rem`,
        fontSize: `1.124rem`,
        fontWeight: fontWeights.bold,
        lineHeight: 1.33,
        textAlign: `center`,
        width: `90%`,
      }}
    >
      {title}
    </h3>
  </div>
)
export default ServiceCard
