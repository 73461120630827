// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

import { colors } from "../../utils/theme/colors"
import { fontWeights } from "../../utils/theme/font-weights"
import Container from "../layout/container"
import Button from "../button"
import _content from "../../content/homepage/cardsSection.json"
import ServiceCards from "../common/ServiceCards"
import Span from "../common/span"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const ServiceCardsSection = ({ langKey }) => {
  const { title1, title2, subtitle, button } = _content[langKey]

  const data = useStaticQuery(graphql`
    query HomepageCardsBackgroundQuery {
      desktop: file(relativePath: { eq: "backgrounds/circles-light.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      fluid={data.desktop.childImageSharp.fluid}
      sx={{
        backgroundSize: `cover`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `center bottom`,
      }}
    >
      <Container>
        <div
          sx={{
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
            position: "relative",
          }}
        >
          <div
            sx={{
              display: `flex`,
              flexDirection: `column`,
              alignItems: `center`,
              justifyContent: "center",
              backgroundColor: colors.white,
              borderRadius: `12px`,
              width: [`95%`, `95%`, `75%`, `65%`],
              paddingX: `1rem`,
              top: [0, 0, "-65px", "-65px"],
              position: "relative",
              height: "180px",
            }}
          >
            <div sx={{ display: `flex` }}>
              <h3
                sx={{
                  fontWeight: fontWeights.black,
                  color: colors.code.secondary,
                  fontSize: [`1.38rem`, `1.38rem`, `2.25rem`, `2.25rem`],
                  lineHeight: 1.2,
                  textAlign: `center`,
                }}
              >
                <Span text={title1} />
                {title2}
              </h3>
            </div>
            <p
              sx={{
                fontWeight: fontWeights.normal,
                color: colors.code.secondary,
                lineHeight: 1.2,
                textAlign: `center`,
                fontSize: [`0.938rem`, `0.938rem`, `1.375rem`, `1.375rem`],
              }}
            >
              {subtitle}
            </p>
          </div>
          <ServiceCards langKey={langKey} />
          <div
            sx={{
              display: `flex`,
              alignItems: `center`,
              justifyContent: `center`,
              width: `100%`,
              height: `15rem`,
            }}
          >
            <AnchorLink
              sx={{
                textDecoration: `none`,
              }}
              to={
                langKey === "en"
                  ? "/amazon-agency#services/"
                  : `/${langKey}/amazon-agency#services/`
              }
            >
              <Button
                overrideCSS={{
                  fontWeight: fontWeights.bold,
                  px: "1.5rem",
                }}
              >
                {button}
              </Button>
            </AnchorLink>
          </div>
        </div>
      </Container>
    </BackgroundImage>
  )
}

export default ServiceCardsSection
