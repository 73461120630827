// eslint-disable-next-line no-unused-vars
import React, { useState } from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { Alert } from "theme-ui"
import { colors } from "../../../utils/theme/colors"
import Button from "../../button"
import Content from "../../../content/homepage/alert.json"
import { FaTimes } from "react-icons/fa"

const Banner = ({ langKey }) => {
  const { text, button } = Content[langKey]
  const [isVissible, setIsVissible] = useState(true)

  const closeAllert = () => {
    setIsVissible(false)
  }

  return (
    <Alert
      sx={{
        position: `absolute`,
        top: [`25%`, `25%`, `90px`, `90px`],
        width: [`85%`, `80%`, `90%`, `80%`],
        py: `15px`,
        zIndex: 5,
        alignItems: `center`,
        fontSize: [`1.4rem`, `1.4rem`, `1.8rem`, `1.8rem`],
        justifyContent: `space-between`,
        display: isVissible ? `flex` : `none`,
        flexDirection: [`column`, `column`, `row`, `row`],
        backgroundColor: [
          `rgba(255, 164, 27, 0.9)`,
          `rgba(255, 164, 27, 0.9)`,
          `rgba(255, 164, 27, 0.7)`,
          `rgba(255, 164, 27, 0.7)`,
        ],
      }}
    >
      <FaTimes
        sx={{
          color: colors.white,
          fontSize: `1.5rem`,
          display: [`flex`, `flex`, `none`, `none`],
          alignSelf: `flex-end`,
        }}
        onClick={() => closeAllert()}
      />
      {text}
      <div
        sx={{
          display: `flex`,
          alignItems: `center`,
          justifyContent: `space-between`,
        }}
      >
        <Button
          overrideCSS={{
            color: colors.code.primary,
            backgroundColor: colors.white,
            fontSize: [`1rem`, `1rem`, `1.3rem`, `1.3rem`],
            px: `10px`,
            width: `100%`,
            opacity: 1,
            marginRight: [`0px`, `0px`, `10px`, `10px`],
            marginTop: [`10px`, `10px`, `0px`, `0px`],
            ":hover": {
              boxShadow: `0px 1px 20px #1F1F1F33`,
              cursor: `pointer`,
            },
          }}
        >
          <Link
            sx={{
              textDecoration: `none`,
              color: colors.code.primary,
            }}
            to={
              langKey === "en"
                ? "/blog/seelk-the-amazon-specialist-joins-jellyfish/"
                : `/fr/blog/seelk-le-specialiste-damazon-rejoint-jellyfish/`
            }
          >
            {button}
          </Link>
        </Button>
        <FaTimes
          sx={{
            color: colors.white,
            fontSize: `2rem`,
            display: [`none`, `none`, `flex`, `flex`],
          }}
          onClick={() => closeAllert()}
        />
      </div>
    </Alert>
  )
}

export default Banner
