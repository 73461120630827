// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { colors } from "../../utils/theme/colors"
import { fontWeights } from "../../utils/theme/font-weights"
import Container from "../layout/container"
import _content from "../../content/homepage/cardsSlider.json"
import Card from "../homepage/blogCard"

const CardsSlider = ({ langKey, blogContent }) => {
  const { title } = _content[langKey]

  return (
    <Container
      overrideCSS={{
        paddingBottom: "8rem",
      }}
    >
      <div
        sx={{
          alignItems: `center`,
          display: `flex`,
          flexDirection: `column`,
        }}
      >
        <div
          sx={{
            marginTop: "-25rem",
            marginBottom: [`1rem`, `1rem`, `6rem`, `6rem`],
            width: [`100%`, `100%`, `40%`, `40%`],
          }}
        >
          <h2
            sx={{
              fontSize: [`1.38rem`, `1.38rem`, `1.8rem`, `1.8rem`],
              fontWeight: fontWeights.black,
              lineHeight: 1.2,
              textAlign: `center`,
              color: colors.code.secondary,
              alignSelf: `center`,
              marginTop: "1rem",
            }}
          >
            {title}
          </h2>
        </div>

        <div
          sx={{
            width: `100%`,
            display: `flex`,
            flexDirection: `row`,
            justifyContent: `space-between`,
            overflowX: "auto",
          }}
        >
          {blogContent &&
            blogContent.map(blogContent => (
              <Card
                key={blogContent.slug}
                title={blogContent.title}
                date={blogContent.published_at_pretty}
                to={
                  langKey === `en`
                    ? `/blog/${blogContent.slug}/`
                    : `/${langKey}/blog/${blogContent.slug}/`
                }
                fluid={blogContent.featureImageSharp.childImageSharp.fluid}
              />
            ))}
        </div>
      </div>
    </Container>
  )
}

export default CardsSlider
