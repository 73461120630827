// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { colors } from "../../utils/theme/colors"
import { fontWeights } from "../../utils/theme/font-weights"

const PinInfo = ({
  title,
  subtitle,
  topSmallDesktop,
  topBigDesktop,
  leftSmallDesktop,
  leftBigDesktop,
}) => {
  return (
    <div
      sx={{
        maxWidth: `18rem`,
        opacity: `0.85`,
        borderRadius: `10px`,
        backgroundColor: colors.code.secondary,
        px: `1rem`,
        py: `1rem`,
        top: [null, null, topSmallDesktop, topBigDesktop],
        left: [null, null, leftSmallDesktop, leftBigDesktop],
        position: `absolute`,
      }}
    >
      <p
        sx={{
          color: colors.white,
          fontWeight: fontWeights.black,
          fontSize: `1.38rem`,
          lineHeight: `1.3`,
          margin: 0,
        }}
      >
        {title}
      </p>
      <p
        sx={{
          color: colors.white,
          margin: 0,
        }}
      >
        {subtitle}
      </p>
    </div>
  )
}

export default PinInfo
