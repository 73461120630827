// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { graphql } from "gatsby"

const RightLogos = props => {
  const data = useStaticQuery(graphql`
    query RightLogosQuery {
      Bic: file(relativePath: { eq: "logos/bic.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fixed(width: 125, height: 40, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      Playmobil: file(relativePath: { eq: "logos/playmobil.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fixed(width: 101, height: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      Sika: file(relativePath: { eq: "logos/sika.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fixed(height: 40, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      Seb: file(relativePath: { eq: "logos/seb.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fixed(width: 175, height: 40, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  const rightLogos = [
    data.Bic.childImageSharp.fixed,
    data.Sika.childImageSharp.fixed,
    data.Seb.childImageSharp.fixed,
    data.Playmobil.childImageSharp.fixed,
  ]
  return (
    <>
      <div
        sx={{
          display: ["none", "none", "grid", "grid"],
          columnGap: 3,
          rowGap: "40px",
          gridTemplateColumns: "1fr  1fr",

          gridTemplateRows: "1fr",
          marginRight: "2rem",
        }}
      >
        {rightLogos.map((logo, i) => (
          <Img
            alt=""
            key={i}
            fixed={logo}
            sx={{
              margin: `auto`,
              filter: "grayscale(0%)",
              opacity: 1,
              ":hover": { filter: `grayscale(100%)`, opacity: `0.8` },
            }}
          />
        ))}
      </div>
    </>
  )
}

RightLogos.propTypes = {}

export default RightLogos
