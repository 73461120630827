// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { mediaQueries } from "../../utils/theme/media-queries"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PinInfo from "./pinInfo"

const MapDesktop = ({
  pin1Title,
  pin1Subtitle,
  pin2Title,
  pin2Subtitle,
  pin3Title,
  pin3Subtitle,
  pin4Title,
  pin4Subtitle,
  pin5Title,
  pin5Subtitle,
}) => {
  const data = useStaticQuery(graphql`
    query MapSectionDesktopQuery {
      MapPic: file(relativePath: { eq: "homepageMap.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fixed(width: 1200, height: 500) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <div
        sx={{
          display: `none`,
          [mediaQueries.sm]: {
            display: `flex`,
            alignSelf: `center`,
          },
        }}
      >
        <Img
          alt=""
          fixed={data.MapPic.childImageSharp.fixed}
          sx={{ top: `55px` }}
        />
      </div>

      <PinInfo
        title={pin1Title}
        subtitle={pin1Subtitle}
        topBigDesktop={"43%"}
        leftBigDesktop={"24%"}
        topSmallDesktop={"41%"}
        leftSmallDesktop={`22%`}
      />

      <PinInfo
        title={pin2Title}
        subtitle={pin2Subtitle}
        topBigDesktop={"41%"}
        leftBigDesktop={"50%"}
        topSmallDesktop={"39%"}
        leftSmallDesktop={`56%`}
      />

      <PinInfo
        title={pin3Title}
        subtitle={pin3Subtitle}
        topBigDesktop={"49%"}
        leftBigDesktop={"76%"}
        topSmallDesktop={"55.5%"}
        leftSmallDesktop={`80%`}
      />

      <PinInfo
        title={pin4Title}
        subtitle={pin4Subtitle}
        topBigDesktop={"54%"}
        leftBigDesktop={"61%"}
        topSmallDesktop={"52%"}
        leftSmallDesktop={`52%`}
      />

      <PinInfo
        title={pin5Title}
        subtitle={pin5Subtitle}
        topBigDesktop={"60%"}
        leftBigDesktop={"33%"}
        topSmallDesktop={"59%"}
        leftSmallDesktop={`11%`}
      />
    </>
  )
}

export default MapDesktop
