// eslint-disable-next-line no-unused-vars
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { colors } from "../../utils/theme/colors"
import { rhythm } from "../../utils/typography"
import { fontWeights } from "../../utils/theme/font-weights"
import Button from "../button"
import ContactRedirection from "../contact-redirection"
import _content from "../../content/homepage/plateformeSaaS.json"

const Paragraph = ({ fixed, title, subtitle }) => (
  <div
    sx={{
      display: `flex`,
      marginTop: [`0.5rem`, `0.5rem`, `1.5rem`, `1.5rem`],
      alignItems: `center`,
      flexDirection: [`column`, `column`, `row`, `row`],
      justifyContent: [null, null, `space-between`, `space-between`],
    }}
  >
    <div
      sx={{
        width: `10%`,
        marginRight: `2.3rem`,
        marginBottom: [`1rem`, `1rem`, `0rem`, `0rem`],
        marginTop: [`1rem`, `1rem`, `0rem`, `0rem`],
      }}
    >
      <Img alt="" fixed={fixed} />
    </div>
    <div
      sx={{
        display: `flex`,
        flexDirection: `column`,
        width: ["100%", "100%", "78%", "78%"],
        paddingTop: [0, 0, "2rem", "2rem"],
        alignItems: [`center`, `center`, `flex-start`, `left`],
      }}
    >
      <h2
        sx={{
          fontSize: `1.063rem`,
          fontWeight: fontWeights.semiBold,
          margin: 0,
          textAlign: [`center`, "center", "left", "left"],
        }}
      >
        {title}
      </h2>
      <p
        sx={{
          fontSize: `0.94rem`,
          fontWeight: fontWeights.body,
          margin: 0,
          textAlign: [`center`, "center", "left", "left"],
          width: `80%`,
        }}
      >
        {subtitle}
      </p>
    </div>
  </div>
)

const PlateformeSaaS = ({ langKey }) => {
  const {
    title,
    subtitle,
    p1Title,
    p1Subtitle,
    p2Title,
    p2Subtitle,
    p3Title,
    p3Subtitle,
    button,
  } = _content[langKey]
  const data = useStaticQuery(graphql`
    query PlateformeSaaSQuery {
      HomePlateformeSaaSScreens: file(
        relativePath: { eq: "HomePlateformeSaaSScreens.png" }
      ) {
        childImageSharp {
          fixed(width: 956, height: 714) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      HomePlateformeSaaSScreensMobileFluid: file(
        relativePath: { eq: "HomePlateformeSaaSScreens.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Monitoring: file(relativePath: { eq: "icon-monitoring-prix.png" }) {
        childImageSharp {
          fixed(width: 80, height: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      MonitoringMobile: file(relativePath: { eq: "icon-monitoring-prix.png" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      Performance: file(relativePath: { eq: "icon-performances-seo.png" }) {
        childImageSharp {
          fixed(width: 80, height: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      PerformanceMobile: file(
        relativePath: { eq: "icon-performances-seo.png" }
      ) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      SuiviConcurrence: file(
        relativePath: { eq: "icon-suivi-concurrence.png" }
      ) {
        childImageSharp {
          fixed(width: 80, height: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      SuiviConcurrenceMobile: file(
        relativePath: { eq: "icon-suivi-concurrence.png" }
      ) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      formation: file(relativePath: { eq: "illustration-formations.png" }) {
        childImageSharp {
          fixed(width: 155, height: 150) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <div
        sx={{
          width: `0`,
          height: [`3rem`, "3rem", `7.38rem`, `7.38rem`],
          objectFit: `contain`,
          border: `solid 1px`,
          margin: `auto`,
          marginBottom: rhythm(1),
          color: colors.code.primary,
        }}
      />
      <div
        sx={{
          display: `flex`,
          marginTop: `2rem`,
          flexDirection: [`column`, `column`, "row", "row"],
          mb: ["50px", "50px", null, null],
        }}
      >
        <Img
          alt=""
          sx={{
            display: [
              `none !important`,
              "none !important",
              "flex !important",
              "flex !important",
            ],
            "& > picture > img": {
              objectFit: "cover !important",
              objectPosition: "right center !important",
            },
          }}
          fixed={data.HomePlateformeSaaSScreens.childImageSharp.fixed}
        />
        <Img
          alt=""
          sx={{
            display: [
              `block !important`,
              "block !important",
              "none !important",
              "none !important",
            ],
          }}
          fluid={
            data.HomePlateformeSaaSScreensMobileFluid.childImageSharp.fluid
          }
        />
        <div
          sx={{
            display: `flex`,
            flexDirection: `column`,
            maxWidth: [null, null, 1366 / 2, 1920 / 2],
            pr: [null, null, "2.688rem", "12.88rem"],
            alignItems: `center`,
            mx: [`1.7rem`],
          }}
        >
          <div
            sx={{
              display: `flex`,
              flexDirection: `column`,
              textAlign: ["center", "center", "left", "left"],
            }}
          >
            <h2
              sx={{
                fontWeight: fontWeights.black,
                margin: 0,
                fontSize: [`1.375rem`, `1.375rem`, `2.25rem`, `2.25rem`],
              }}
            >
              {title}
            </h2>
            <p
              sx={{
                lineHeight: `1.2`,
                fontWeight: fontWeights.body,
                color: colors.code.secondary,
                textAlign: `left`,
                margin: `auto`,
                fontSize: [`0.94rem`, "0.94rem", "1.375rem", "1.375rem"],
              }}
            >
              {subtitle}
            </p>
          </div>
          <div
            sx={{
              marginBottom: `2.75rem`,
              display: [`none`, "none", "flex", "flex"],
              marginLeft: [0, 0, "2.6rem", "2.6rem"],
              flexDirection: [null, null, "column", "column"],
            }}
          >
            <Paragraph
              fixed={data.Monitoring.childImageSharp.fixed}
              title={p1Title}
              subtitle={p1Subtitle}
            />
            <Paragraph
              fixed={data.Performance.childImageSharp.fixed}
              title={p2Title}
              subtitle={p2Subtitle}
            />
            <Paragraph
              fixed={data.SuiviConcurrence.childImageSharp.fixed}
              title={p3Title}
              subtitle={p3Subtitle}
            />
          </div>
          <div
            sx={{
              marginBottom: `2.75rem`,
              display: [`flex`, "flex", "none", "none"],
              flexDirection: [`column`, "column", null, null],
              marginLeft: [0, 0, "2.6rem", "2.6rem"],
            }}
          >
            <Paragraph
              fixed={data.MonitoringMobile.childImageSharp.fixed}
              title={p1Title}
              subtitle={p1Subtitle}
            />
            <Paragraph
              fixed={data.PerformanceMobile.childImageSharp.fixed}
              title={p2Title}
              subtitle={p2Subtitle}
            />
            <Paragraph
              fixed={data.SuiviConcurrenceMobile.childImageSharp.fixed}
              title={p3Title}
              subtitle={p3Subtitle}
            />
          </div>
          <ContactRedirection
            href={
              langKey === "en"
                ? "https://www.jellyfish.com/en-us/contact-us"
                : "https://www.jellyfish.com/fr-fr/contact-us"
            }
            buttonContent={button}
          />
        </div>
      </div>
    </>
  )
}

export default PlateformeSaaS
