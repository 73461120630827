// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { colors } from "../../../utils/theme/colors"
import { fontWeights } from "../../../utils/theme/font-weights"

const ParagraphMobile = ({ number, text }) => {
  return (
    <div
      sx={{
        display: `flex`,
        alignItems: `center`,
      }}
    >
      <p
        sx={{
          color: colors.code.primary,
          fontSize: `2rem`,
          fontWeight: fontWeights.black,
          marginBottom: `0px`,
          marginRight: `1rem`,
        }}
      >
        {number}
      </p>
      <p
        sx={{
          color: colors.white,
          fontWeight: fontWeights.semiBold,
          fontSize: `1.125rem`,
          marginBottom: `0px`,
        }}
      >
        {text}
      </p>
    </div>
  )
}

export default ParagraphMobile
