// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { colors } from "../../utils/theme/colors"
import { fontWeights } from "../../utils/theme/font-weights"
import Img from "gatsby-image"
import { Link } from "gatsby"

const blogCard = ({ title, subtitle, date, to, fluid }) => (
  <Link to={to} target="_blank" sx={{ textDecoration: `none` }}>
    <div
      sx={{
        display: `flex`,
        width: [`19.5rem`, `22rem`, `22rem`, `30.5rem`],

        height: `23rem`,
        backgroundColor: colors.white,
        flexDirection: `column`,
        borderRadius: `10px`,
        boxShadow: `0 0 35px 0 rgba(31, 31, 31, 0.07)`,
        overflow: `hidden`,
        marginRight: `1rem`,
      }}
    >
      <Img alt="" sx={{ height: `15rem`, objectFit: `cover` }} fluid={fluid} />

      <div
        sx={{
          display: `flex`,
          flexDirection: `column`,
          marginTop: `2rem`,
          marginLeft: `1.75rem`,
        }}
      >
        <h3
          sx={{
            fontSize: `1.375rem`,
            lineHeight: 1.2,
            fontWeight: fontWeights.black,
            color: colors.code.secondary,
            margin: 0,
            mx: `0.5rem`,
          }}
        >
          {title}
        </h3>
        <p
          sx={{
            fontSize: `1.06rem`,
            lineHeight: 1.2,
            fontWeight: fontWeights.body,
            color: colors.code.primary,
            margin: 0,
          }}
        >
          {subtitle}
        </p>
      </div>
      <div
        sx={{
          backgroundColor: colors.code.secondary,
          alignItems: `center`,
          position: `absolute`,
          top: [`-45%`, `-45%`, `-75%`, `-75%`],
          display: [`none`, `none`, `flex`, `flex`],
        }}
      >
        <h3
          sx={{
            color: colors.white,
            fontSize: `1.13rem`,
            lineHeight: 1.2,
            marginTop: `0.5rem`,
            mx: `1rem`,
            my: `0.5rem`,
          }}
        >
          {date}
        </h3>
      </div>
    </div>
  </Link>
)

export default blogCard
