// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { colors } from "../../utils/theme/colors"
import { fontWeights } from "../../utils/theme/font-weights"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { mediaQueries } from "../../utils/theme/media-queries"
import Container from "../layout/container"
import _content from "../../content/homepage/EquipeExperts.json"
import Span from "../common/span"

const EquipeExperts = ({ langKey }) => {
  const {
    title1,
    title2,
    title3,
    title4,
    title5,
    title6,
    title7,
    title8,
  } = _content[langKey]
  const data = useStaticQuery(graphql`
    query EquipeExpertsQuery {
      EquipeExpertsPic: file(relativePath: { eq: "EquipeExperts.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fixed(width: 417, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      EquipeExpertsPicMobile: file(relativePath: { eq: "EquipeExperts.png" }) {
        childImageSharp {
          fixed(width: 315) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <Container>
      <div
        sx={{
          display: `flex`,
          flexDirection: `column`,
          alignItems: `center`,
          marginBottom: ["1rem", "1rem", "2rem", "2rem"],
        }}
      >
        <div
          sx={{
            display: `none`,
            marginTop: "-3rem",
            [mediaQueries.sm]: {
              display: `flex`,
            },
          }}
        >
          <Img alt="" fixed={data.EquipeExpertsPic.childImageSharp.fixed} />
        </div>
        <div
          sx={{
            display: `flex`,
            marginTop: `-15%`,
            [mediaQueries.sm]: {
              display: `none`,
            },
          }}
        >
          <Img
            alt=""
            fixed={data.EquipeExpertsPicMobile.childImageSharp.fixed}
          />
        </div>
        <div
          sx={{
            display: `flex`,
            marginTop: `-4rem`,
            flexDirection: `column`,
            alignItems: `center`,
          }}
        >
          <div sx={{ display: `flex`, width: [`100%`, `100%`, `60%`, `45%`] }}>
            <h3
              sx={{
                fontWeight: fontWeights.black,
                textAlign: `center`,
                alignSelf: `center`,
                color: colors.code.secondary,
                zIndex: 4,
                fontSize: [`1.375rem`, `1.375rem`, `2.25rem`, `2.25rem`],
                lineHeight: `1.2`,
              }}
            >
              {title1}&nbsp;
              <Span text={title2} />
              &nbsp;
              {title3}&nbsp;
              <Span text={title4} />
              &nbsp;
              {title5}&nbsp;
              {title6}&nbsp;
              <Span text={title7} />
              &nbsp;
              {title8}
            </h3>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default EquipeExperts
