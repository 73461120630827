// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { graphql } from "gatsby"

const LeftLogos = props => {
  const data = useStaticQuery(graphql`
    query LeftLogosQuery {
      Schleich: file(relativePath: { eq: "logos/schleich.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fixed(width: 162, height: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      Elizabet: file(relativePath: { eq: "logos/elizabeth-arden.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fixed(width: 150, height: 72, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      Colgate: file(relativePath: { eq: "logos/Colgate.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fixed(height: 40, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      Lacoste: file(relativePath: { eq: "logos/lacoste.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fixed(height: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  const leftLogos = [
    data.Schleich.childImageSharp.fixed,
    data.Elizabet.childImageSharp.fixed,
    data.Colgate.childImageSharp.fixed,
    data.Lacoste.childImageSharp.fixed,
  ]
  return (
    <>
      <div
        sx={{
          display: ["none", "none", "grid", "grid"],
          columnGap: 3,
          rowGap: "40px",
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr",
          marginLeft: "2rem",
        }}
      >
        {leftLogos.map((logo, i) => (
          <Img
            alt=""
            key={i}
            fixed={logo}
            sx={{
              margin: `auto`,
              filter: "grayscale(0%)",
              opacity: 1,
              ":hover": { filter: `grayscale(100%)`, opacity: `0.8` },
            }}
          />
        ))}
      </div>
    </>
  )
}

LeftLogos.propTypes = {}

export default LeftLogos
