// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { colors } from "../../utils/theme/colors"
import { fontWeights } from "../../utils/theme/font-weights"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const MapMobile = ({
  pin1Title,
  pin1Subtitle,
  pin2Title,
  pin2Subtitle,
  pin3Title,
  pin3Subtitle,
  pin4Title,
  pin4Subtitle,
  pin5Title,
  pin5Subtitle,
}) => {
  const data = useStaticQuery(graphql`
    query MapSectionMobileQuery {
      Amerique: file(relativePath: { eq: "Amerique.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fluid(maxWidth: 280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Asie: file(relativePath: { eq: "Asie.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Europe: file(relativePath: { eq: "Europe.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      EuropeFixed: file(relativePath: { eq: "Europe.png" }) {
        childImageSharp {
          fixed(width: 250, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      Emirates: file(relativePath: { eq: "Emirates.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const Map = ({ title, subtitle }) => (
    <div
      sx={{
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        opacity: `0.85`,
        borderRadius: `10px`,
        backgroundColor: colors.code.secondary,
        zIndex: 5,
        flexDirection: `column`,
        margin: `auto`,
        width: `16.5rem`,
        minHeight: `6rem`,
        mx: `1.5rem`,
      }}
    >
      <p
        sx={{
          color: colors.white,
          fontWeight: fontWeights.black,
          fontSize: `1.38rem`,
          lineHeight: `1.3`,
          margin: 0,
          textAlign: `center`,
          marginTop: `1rem`,
        }}
      >
        {title}
      </p>
      <p
        sx={{
          color: colors.white,
          margin: 0,
          textAlign: `center`,
        }}
      >
        {subtitle}
      </p>
    </div>
  )
  return (
    <>
      <div
        sx={{
          display: `flex`,
          flexDirection: `row`,
          justifyContent: `space-between`,
          overflowX: "auto",
        }}
      >
        <BackgroundImage
          fluid={data.Amerique.childImageSharp.fluid}
          sx={{ height: `12rem`, width: `19rem` }}
        >
          <Map title={pin1Title} subtitle={pin1Subtitle} />
        </BackgroundImage>
        <BackgroundImage
          fluid={data.Europe.childImageSharp.fluid}
          sx={{ alignItems: `center` }}
        >
          <Map title={pin2Title} subtitle={pin2Subtitle} />
        </BackgroundImage>
        <BackgroundImage
          fluid={data.Asie.childImageSharp.fluid}
          sx={{ alignItems: `center` }}
        >
          <Map title={pin3Title} subtitle={pin3Subtitle} />
        </BackgroundImage>
        <BackgroundImage
          fluid={data.Amerique.childImageSharp.fluid}
          sx={{ alignItems: `center` }}
        >
          <Map title={pin4Title} subtitle={pin4Subtitle} />
        </BackgroundImage>

        <BackgroundImage
          fluid={data.Amerique.childImageSharp.fluid}
          sx={{ alignItems: `center` }}
        >
          <Map title={pin5Title} subtitle={pin5Subtitle} />
        </BackgroundImage>
      </div>
    </>
  )
}

export default MapMobile
