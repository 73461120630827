// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { colors } from "../../../utils/theme/colors"
import { fontWeights } from "../../../utils/theme/font-weights"
import { mediaQueries } from "../../../utils/theme/media-queries"
import Container from "../../layout/container"
import ContactRedirection from "../../contact-redirection"
import _content from "../../../content/homepage/hero.json"
import ParagraphMobile from "./paragraph-mobile"
import BackgroundImage from "gatsby-background-image"
import Banner from "./banner"

const Card = ({ number, text }) => (
  <div
    sx={{
      background: colors.white,
      borderRadius: `20px`,
      width: `12.2rem`,
      height: `4.5rem`,
      marginRight: `1.3rem`,
      color: colors.code.secondary,
      display: `flex`,
      alignItems: `center`,
      justifyContent: "center",
      marginBottom: 0,
      boxShadow: `0 0 35px 0 rgba(31, 31, 31, 0.15)`,
    }}
  >
    <p
      sx={{
        color: colors.code.primary,
        marginRight: `1.05rem`,
        fontSize: [`1.625rem`, "1.625rem", "2.25rem", "2.25rem"],
        alignSelf: "flex-start",
        marginBottom: 0,
        [mediaQueries.lg]: {
          fontWeight: fontWeights.black,
        },
      }}
    >
      {number}
    </p>
    <div
      sx={{
        color: colors.secondary,
        fontWeight: fontWeights.semiBold,
        fontSize: `1rem`,
        width: "60%",
        marginBottom: 0,
      }}
    >
      {text}
    </div>
  </div>
)

const Hero = ({ langKey }) => {
  const { title1, title2, title3, card1, card2, card3, button } = _content[
    langKey
  ]
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      heroImageDesktop: file(relativePath: { eq: "Homepage_hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      heroImageMobile: file(relativePath: { eq: "Homepage_heroMobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      background: file(relativePath: { eq: "backgrounds/galaxy.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const sources = [
    data.heroImageMobile.childImageSharp.fluid,
    {
      ...data.heroImageDesktop.childImageSharp.fluid,
      media: `(min-width: 1920px)`,
    },
  ]

  return (
    <BackgroundImage fluid={data.background.childImageSharp.fluid}>
      <Container>
        <Banner langKey={langKey} />
        <div
          sx={{
            minHeight: ["100vh", "100vh", "25.375rem", "25.375rem"],
            display: `flex`,
            flexDirection: [`column`, "column", "row", "row"],
            justifyContent: "space-evenly",
            paddingTop: [`3rem`, `3rem`, "0rem", "0rem"],
            alignItems: `center`,
          }}
        >
          <div
            sx={{
              marginTop: `1.5rem`,
              marginRight: [null, null, null, `7rem`],
            }}
          >
            <div sx={{ order: [1, 1, 0, 0] }}>
              <h1
                sx={{
                  color: colors.white,
                  fontWeight: fontWeights.black,
                  fontSize: [`1.625rem`, "1.625rem", "3.5rem", "3.5rem"],
                  margin: 0,
                  alignItems: [null, null, `center`, `center`],
                  display: [null, null, `flex`, `flex`],
                  flexDirection: [null, null, `row`, `row`],
                }}
              >
                {title1} <br /> {title2} <br />{" "}
              </h1>
              <h2
                sx={{
                  background: `linear-gradient(180deg, transparent 50%, rgba(255, 164, 27, 0.7) 50%)`,
                  color: colors.white,
                  fontWeight: fontWeights.black,
                  fontSize: [`1.625rem`, "1.625rem", "3.5rem", "3.5rem"],
                  width: ["75%", "75%", "52%", "52%"],
                  display: [null, null, `flex`, `flex`],
                  flexDirection: [null, null, `row`, `row`],
                  alignItems: [null, null, `center`, `center`],
                }}
              >
                {title3}
              </h2>
            </div>
            <div
              sx={{
                display: [`none`, "none", "flex", "flex"],
                marginTop: [0, 0, "3rem", "3rem"],
                marginBottom: [0, 0, "2.4rem", "2.4rem"],
              }}
            >
              <Card number={`1`} text={card1} />
              <Card number={`2`} text={card2} />
              <Card number={`3`} text={card3} />
            </div>
          </div>
          <div
            sx={{
              display: [`none`, "none", "block", "block"],
              width: [null, null, "40%", "100%"],
              marginTop: `15rem`,
            }}
          >
            <Img alt="" fluid={sources} />
          </div>
          <div
            sx={{
              display: [`block`, "block", "none", "none"],
              mx: "1.625rem",
              width: "100%",
              order: [2, 2, 0, 0],
            }}
          >
            <Img fluid={data.heroImageMobile.childImageSharp.fluid} />
          </div>
          <div
            sx={{
              display: [`flex`, "flex", "none", "none"],
              flexDirection: `column`,
              alignItems: `baseline`,
              order: [3, 3, 0, 0],
              my: `1rem`,
            }}
          >
            <ParagraphMobile text={card1} number={"1"} />
            <ParagraphMobile text={card2} number={"2"} />
            <ParagraphMobile text={card3} number={"3"} />
          </div>
        </div>
        <div
          sx={{
            transform: "translateY(25px)",
          }}
        >
          <ContactRedirection
            href={
              langKey === "en"
                ? "https://www.jellyfish.com/en-us/contact-us"
                : "https://www.jellyfish.com/fr-fr/contact-us"
            }
            buttonContent={button}
            overrideCSS={{ width: "fit-content" }}
          />
        </div>
      </Container>
    </BackgroundImage>
  )
}

export default Hero
