// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Container from "../layout/container"
import Content from "../../content/homepage/talkAboutUs.json"
import { colors } from "../../utils/theme/colors"
import { fontWeights } from "../../utils/theme/font-weights"

const TalkAboutUs = ({ langKey }) => {
  const data = useStaticQuery(graphql`
    query TalkAboutUsQuery {
      JDN: file(relativePath: { eq: "JDN.png" }) {
        childImageSharp {
          fixed(width: 80, height: 80, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      Ecommercemag: file(relativePath: { eq: "ecommercemag.png" }) {
        childImageSharp {
          fixed(width: 140, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      Fevad: file(relativePath: { eq: "logos/Fevad.png" }) {
        childImageSharp {
          fixed(width: 70, height: 70, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      FrenchWeb: file(relativePath: { eq: "FrenchWeb.png" }) {
        childImageSharp {
          fixed(width: 80, height: 80, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      BFM: file(relativePath: { eq: "BFM.png" }) {
        childImageSharp {
          fixed(width: 80, height: 80, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      LesEchos: file(relativePath: { eq: "LesEchos.png" }) {
        childImageSharp {
          fixed(width: 100, height: 80, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      SeelkLogo: file(relativePath: { eq: "logo-separator.png" }) {
        childImageSharp {
          fixed(width: 30, height: 45) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const title = Content[langKey].title
  return (
    <Container>
      <div
        sx={{
          display: `flex`,
          paddingBottom: ["5rem", "5rem", "8rem", "8rem"],
          alignItems: `center`,
          flexDirection: `column`,
        }}
      >
        <h2
          sx={{
            display: ["flex", "flex", "flex", "none"],
            fontSize: [`1.375rem`, `1.375rem`, `2.25rem`, null],
            color: colors.code.secondary,
            fontWeight: fontWeights.black,
            width: `70%`,
            justifyContent: `center`,
            textAlign: `center`,
          }}
        >
          {title}
        </h2>
        <Img
          alt=""
          fixed={data.SeelkLogo.childImageSharp.fixed}
          sx={{ marginBottom: [`2rem`, `2rem`, `7rem`, `7rem`] }}
        />
        <div
          sx={{
            width: ["100%", "80%", "80%", "80%"],
            display: "grid",
            gridTemplateColumns: [
              "repeat(3, 1fr)",
              "repeat(3, 1fr)",
              "repeat(6, 1fr)",
              "repeat(6, 1fr)",
            ],
            gridTemplateRows: [
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(1, 1fr)",
              "repeat(1, 1fr)",
            ],
            rowGap: "2rem",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <Link
            sx={{
              textDecoration: `none`,
            }}
            to={"/blog/seelk-wants-to-optimize-your-sales-on-amazon/"}
          >
            <Img
              alt=""
              fixed={data.JDN.childImageSharp.fixed}
              sx={{ ":hover": { filter: `grayscale(100%)` } }}
            />
          </Link>
          <Link
            sx={{
              textDecoration: `none`,
            }}
            to={"/fr/blog/seelk-et-sika-vainqueurs-trophee-e-commerce/"}
          >
            <Img
              alt=""
              fixed={data.Ecommercemag.childImageSharp.fixed}
              sx={{ ":hover": { filter: `grayscale(100%)` } }}
            />
          </Link>
          <Link
            sx={{
              textDecoration: `none`,
            }}
            to={
              langKey === "en"
                ? "/blog/seelk-finalist-fevad"
                : `/${langKey}/blog/seelk-finaliste-du-concours-start-me-up-2018/`
            }
          >
            <Img
              alt=""
              fixed={data.Fevad.childImageSharp.fixed}
              sx={{ ":hover": { filter: `grayscale(100%)` } }}
            />
          </Link>
          <Link
            sx={{
              textDecoration: `none`,
            }}
            to={"/blog/seelk-wants-to-optimize-your-sales-on-amazon/"}
          >
            <Img
              alt=""
              fixed={data.FrenchWeb.childImageSharp.fixed}
              sx={{ ":hover": { filter: `grayscale(100%)` } }}
            />
          </Link>
          <Link
            sx={{
              textDecoration: `none`,
            }}
            to={"/fr/blog/assistant-vocaux/"}
          >
            <Img
              alt=""
              fixed={data.BFM.childImageSharp.fixed}
              sx={{ ":hover": { filter: `grayscale(100%)` } }}
            />
          </Link>
          <Link
            sx={{
              textDecoration: `none`,
            }}
            to={"/blog/seelk-wants-to-optimize-your-sales-on-amazon/"}
          >
            <Img
              alt=""
              fixed={data.LesEchos.childImageSharp.fixed}
              sx={{ ":hover": { filter: `grayscale(100%)` } }}
            />
          </Link>
        </div>
      </div>
    </Container>
  )
}

export default TalkAboutUs
